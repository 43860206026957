import {
  type ErrorInfo,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useUser } from '@clerk/nextjs';
import { IS_DD_ENABLED } from '@config/env';
import type { Org } from '@cuebox-types/orgs';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import useCueboxUser from '@hooks/staff/useCueboxUser';
import useOrganizations from '@hooks/staff/useOrganizations';

const CUEBOX_STAFF_MEMBER_CONTEXT_KEY = 'cueboxStaffMember';
const ORG_ID_CONTEXT_KEY = 'orgId';
const ORG_NAME_CONTEXT_KEY = 'orgName';

export type ErrorMeta = ErrorInfo | Record<string, unknown>;

interface UserErrorTrackingApiParams {
  isCueboxStaffMember: boolean;
  consumerOrg: Org | undefined;
}

export const useErrorTrackingApi = ({
  isCueboxStaffMember,
  consumerOrg,
}: UserErrorTrackingApiParams) => {
  // Get the staff side org ID from the current user, if available.
  const { user: clerkUser } = useUser();
  const { user } = useCueboxUser({ shouldFetch: !!clerkUser });
  const { currentOrg: staffOrg } = useOrganizations(user?.cueboxUserId);

  useEffect(() => {
    datadogLogs.setGlobalContextProperty(
      CUEBOX_STAFF_MEMBER_CONTEXT_KEY,
      isCueboxStaffMember,
    );
    datadogRum.setGlobalContextProperty(
      CUEBOX_STAFF_MEMBER_CONTEXT_KEY,
      isCueboxStaffMember,
    );

    if (consumerOrg) {
      datadogLogs.setGlobalContextProperty(ORG_ID_CONTEXT_KEY, consumerOrg.id);
      datadogRum.setGlobalContextProperty(ORG_ID_CONTEXT_KEY, consumerOrg.id);
      datadogRum.setUserProperty(ORG_NAME_CONTEXT_KEY, consumerOrg.name);
    } else if (staffOrg) {
      datadogLogs.setGlobalContextProperty(ORG_ID_CONTEXT_KEY, staffOrg.id);
      datadogRum.setGlobalContextProperty(ORG_ID_CONTEXT_KEY, staffOrg.id);
      datadogRum.setUserProperty(ORG_NAME_CONTEXT_KEY, staffOrg.name);
    }
  }, [isCueboxStaffMember, staffOrg, consumerOrg]);

  // Log to datadog based on: https://docs.datadoghq.com/logs/log_collection/javascript/
  // Creating custom loggers here helps us to keep logs organized and set custom severity levels to process logs in the DD dashboard.
  const logger = useMemo(
    () =>
      datadogLogs.createLogger('app', {
        handler: 'http',
      }),
    [],
  );

  const trackError = useCallback(
    (err: unknown, meta?: ErrorMeta, customMessage?: string) => {
      let msg = '[unknown error]';
      if (customMessage) {
        msg = customMessage;
      } else if (err instanceof Error && err.message) {
        msg = err.message;
      }

      if (IS_DD_ENABLED) {
        if (err instanceof Error) {
          logger.error(msg, { meta }, err);
        } else {
          logger.error(msg, { meta });
        }
        datadogRum.addError(err, { meta });
      } else {
        console.error(err);
      }
    },
    [logger],
  );

  const trackWarning = useCallback(
    (err: unknown, meta?: ErrorMeta, customMessage?: string) => {
      let msg = '[unknown warning]';
      if (customMessage) {
        msg = customMessage;
      } else if (err instanceof Error && err.message) {
        msg = err.message;
      }

      if (IS_DD_ENABLED) {
        if (err instanceof Error) {
          logger.warn(msg, { meta }, err);
        } else {
          logger.warn(msg, { meta });
        }
      } else {
        console.warn(msg);
      }
    },
    [logger],
  );

  const trackInfo = useCallback(
    (message: string, meta?: ErrorMeta) => {
      if (IS_DD_ENABLED) {
        logger.setLevel('info');
        logger.info(message, { meta });
      } else {
        console.info(message);
      }
    },
    [logger],
  );

  return {
    trackError,
    trackInfo,
    trackWarning,
  };
};

export type ErrorTrackingContextApi = ReturnType<typeof useErrorTrackingApi>;

export const ErrorTrackingContext = createContext(
  {} as ErrorTrackingContextApi,
);

export const useErrorTracking = () => useContext(ErrorTrackingContext);
