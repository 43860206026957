import type { PlainMessage } from '@bufbuild/protobuf';
import { Code, ConnectError } from '@connectrpc/connect';
import {
  BadRequest,
  type BadRequest_Violation,
} from '@pb/cuebox/common/v1/error_pb';

export type ErrorViolation = PlainMessage<BadRequest_Violation>;

/* Example Error
{
  "code": "failed_precondition",
  "message": "server encountered error, request_id=0c5de17a-1a52-48bc-8172-6b4e3ee42d0c",
  "details": [
    {
      "type": "cuebox.common.v1.BadRequest",
      "value": "Ck0SS2Nhbm5vdCBjaGFuZ2UgbG9jYXRpb24gYmVjYXVzZSB0aWNrZXRzIGhhdmUgYWxyZWFkeSBiZWVuIHNvbGQgZm9yIHRoaXMgc2hvdw",
      "debug": {
        "@type": "type.googleapis.com/cuebox.common.v1.BadRequest",
        "violations": [
          {
            "description": "cannot change location because tickets have already been sold for this show"
          }
        ]
      }
    }
  ]
}
*/

// To retrieve the error details
// Check @bufbuild/connect-web/dist/types/connect-error.d.ts
// which provides the function connectErrorDetails() to retrieve the details.
// Alternatively, refer to https://connect.build/docs/web/errors/#working-with-errors for more information.
export const collectErrorViolations = (err: Error | ConnectError | unknown) => {
  if (err instanceof ConnectError) {
    const badRequest = err.findDetails(BadRequest);

    return badRequest.reduce<BadRequest_Violation[]>((violations, req) => {
      if (req.violations.length) {
        return [...violations, ...req.violations];
      }

      return violations;
    }, []);
  }

  return [];
};

export const getErrorMessage = (
  err: Error | ConnectError | unknown,
  fallback?: string,
) => {
  const violations = collectErrorViolations(err);

  if (violations[0]) {
    return violations[0].description;
  }

  if (!fallback) {
    return 'An error occurred';
  }
  return fallback;
};

/**
 * Get the first error field and message from the error object, if it exists,
 *
 * Otherwise, return the fallback values.
 */
export const getErrorFieldMessage = (
  err: Error | ConnectError | unknown,
  fallback: [string, string] | string = '',
): [string, string] => {
  const fallbackField = Array.isArray(fallback) ? fallback[0] : '';
  const fallbackMessage = Array.isArray(fallback) ? fallback[1] : fallback;

  const violations = collectErrorViolations(err);

  if (violations[0]) {
    return [
      violations[0].field || fallback[0],
      violations[0].description || fallback[1],
    ];
  }

  return [fallbackField, fallbackMessage];
};

export const isNotFoundError = (err: unknown): err is ConnectError => {
  if (err instanceof ConnectError && err.code === Code.NotFound) {
    return true;
  }

  return false;
};
