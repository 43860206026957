// This file contains const mappings for values plucked from .env files
// When adding variables to env files, make sure to update the corresponding file in 1password as well.
//
// ------------------------------------------------------------------------
// IMPORTANT:
//
// Note that all environment variables starting with NEXT_PUBLIC_ must be
// present at build time, so they need to be added to the Dockerfile as an ARG.
//
// ------------------------------------------------------------------------
import type { FinixEnvironment } from '@cuebox-types/finix';

export const isSSR = typeof window === 'undefined';

// List of long-lived Coherence environments that we know are not preview environments.
const KNOWN_COHERENCE_ENV_NAMES = ['main', 'demo', 'production'];

const coherenceEnvName =
  process.env.NEXT_PUBLIC_COHERENCE_ENVIRONMENT_NAME || '';
const coherenceEnvDomain =
  process.env.NEXT_PUBLIC_COHERENCE_ENVIRONMENT_DOMAIN || '';

const isPreviewEnvironment =
  coherenceEnvName && !KNOWN_COHERENCE_ENV_NAMES.includes(coherenceEnvName);

// The root hostname for backend API's (e.g. localhost)
//
// Use NEXT_PUBLIC_CUEBOX_API_URL unless we are in a preview environment.
let cueboxApiUrl = '';
if (isPreviewEnvironment) {
  // coherenceEnvDomain might look like "https://foo.cuebox-app-review.cuebox.coherencesites.com"
  // and we want to turn it into "https://foo.cuebox-api-review.cuebox.coherencesites.com" (api vs. app).
  const tokens = coherenceEnvDomain.split('.');
  const coherenceEnvSubdomain = tokens[0];
  cueboxApiUrl = `https://${coherenceEnvSubdomain}.cuebox-api-review.cuebox.coherencesites.com`;
} else {
  cueboxApiUrl = process.env.NEXT_PUBLIC_CUEBOX_API_URL as string;
}
export const CUEBOX_API_URL = `${cueboxApiUrl}/connect`;

export const R2_ASSET_PUBLIC_ENDPOINT = process.env
  .NEXT_PUBLIC_R2_ASSET_PUBLIC_ENDPOINT as string;

export const CUEBOX_CONSUMER_API_KEY = process.env
  .NEXT_PUBLIC_CUEBOX_CONSUMER_API_KEY as string;

export const TILLED_IS_SANDBOX: boolean = JSON.parse(
  process.env.NEXT_PUBLIC_TILLED_IS_SANDBOX as string,
);

export const TILLED_PUBLIC_KEY = process.env
  .NEXT_PUBLIC_TILLED_PUBLIC_KEY as string;

export const GOOGLE_PLACES_API_KEY = process.env
  .NEXT_PUBLIC_GOOGLE_PLACES_API_KEY as string;

export const SEATSIO_PUBLIC_WORKSPACE_KEY = process.env
  .NEXT_PUBLIC_SEATSIO_PUBLIC_WORKSPACE_KEY as string;

// FYI, we treat "preview" as a well known environment name in Datadog,
// don't change that string without good reason.
export const DD_ENV = isPreviewEnvironment
  ? 'preview'
  : process.env.NEXT_PUBLIC_DD_ENV || 'unknown';

export const IS_DD_ENABLED = JSON.parse(
  process.env.NEXT_PUBLIC_IS_DD_ENABLED as string,
);

export const IS_DD_RUM_RECORDING_ENABLED = JSON.parse(
  process.env.NEXT_PUBLIC_IS_DD_RUM_RECORDING_ENABLED as string,
);

export const DVC_CLIENT_SDK_KEY = process.env
  .NEXT_PUBLIC_DVC_CLIENT_SDK_KEY as string;

export const DD_RUM_APPLICATION_ID = process.env
  .NEXT_PUBLIC_DD_RUM_APPLICATION_ID as string;
export const DD_RUM_CLIENT_TOKEN = process.env
  .NEXT_PUBLIC_DD_RUM_CLIENT_TOKEN as string;
export const DD_LOGS_CLIENT_TOKEN = process.env
  .NEXT_PUBLIC_DD_LOGS_CLIENT_TOKEN as string;

export const INTERCOM_APP_ID = process.env
  .NEXT_PUBLIC_INTERCOM_APP_ID as string;

export const AG_GRID_LICENSE_KEY = process.env
  .NEXT_PUBLIC_AG_GRID_LICENSE_KEY as string;

/** The finix environment for making requests (either `"sandbox"` or `"live"`) */
export const FINIX_ENV = process.env.NEXT_PUBLIC_FINIX_ENV as FinixEnvironment;

export const FINIX_APP_ID = process.env.NEXT_PUBLIC_FINIX_APP_ID ?? '';

export const APP_VERSION = process.env.NEXT_PUBLIC_APP_VERSION;

export const CUEBOX_GTM_ID = process.env.NEXT_PUBLIC_CUEBOX_GTM_ID;

/** The commit hash used for the DataDog version ID */
export const GIT_COMMIT_HASH =
  process.env.NEXT_PUBLIC_CNC_SERVICE_TAG_CUEBOXAPP ||
  process.env.NEXT_PUBLIC_COHERENCE_BUILD_SHA;
