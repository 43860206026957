// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/staff/v1/show_genre.proto (package cuebox.services.staff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from message cuebox.services.staff.v1.ShowGenre
 */
export const ShowGenre = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ShowGenre",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "updated_at", kind: "message", T: Timestamp },
    { no: 4, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 5, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListShowGenresRequest
 */
export const ListShowGenresRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListShowGenresRequest",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.ListShowGenresResponse
 */
export const ListShowGenresResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListShowGenresResponse",
  () => [
    { no: 1, name: "show_genres", kind: "message", T: ShowGenre, repeated: true },
  ],
);

